const DashboardLayout = () => import("../layouts/dashboard.vue");
const dashboard = () => import("../pages/dashboard.vue");
const exports = () => import("../pages/exports.vue");

/**
 * @type {import('vue-router').RouteConfig[]}
 */
export const dashboardRoutes = [
  {
    path: "/dashboard",
    component: DashboardLayout,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        component: dashboard,
      },
      {
        path: "exportacoes",
        component: exports,
      },
    ],
  },
];
