const Dashboard = () => import("../layouts/dashboard.vue");
const DetailsBanner = () => import("../pages/banners/detailsBanner");
const ListBanners = () => import("../pages/banners/listBanners.vue");
const BannersLinks = () => import("../pages/banners/bannersLinks.vue");

/**
 * @type {import('vue-router').RouteConfig[]}
 */
export const bannerRoutes = [
  {
    path: "/banners",
    component: Dashboard,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        component: ListBanners,
      },
      {
        path: "criar",
        component: DetailsBanner,
      },
      {
        path: ":id/editar",
        component: DetailsBanner,
      },
      {
        path: "links",
        component: BannersLinks,
      },
    ],
  },
];
