const Dashboard = () => import("../layouts/dashboard.vue");
const DetailsProducts = () => import("../pages/products/detailsProduct.vue");
const ListProducts = () => import("../pages/products/listProducts.vue");
const ProfileAttribute = () => import("../pages/products/profileAttribute.vue");
const ListCatalogs = () => import("../pages/catalogs/listCatalogs.vue");
const ListGroup = () => import("../pages/group/listGroup.vue");
const ListDepartment = () => import("../pages/department/listDepartment.vue");
/**
 * @type {import('vue-router').RouteConfig[]}
 */
export const productRoutes = [
  {
    path: "/produtos",
    component: Dashboard,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        component: ListProducts,
      },
      {
        path: ":id/editar",
        component: DetailsProducts,
      },
      {
        path: "perfil",
        component: ProfileAttribute,
      },
      {
        path: "catalogo",
        component: ListCatalogs,
      },
      {
        path: "grupo",
        component: ListGroup,
      },
      {
        path: "departamento",
        component: ListDepartment,
      },
    ],
  },
];
