import http from "../plugins/http";

const initState = () => ({
  product: {
    boxId: 0,
    departmentId: 0,
    catalogId: 0,
    groupId: 0,
    productCode: "",
    reference: "",
    completeDescription: "",
    package: "",
    unity: "",
    tags: "",
    weight: "",
    width: "",
    length: "",
    height: "",
    showIpi: false,
    show: false,
    news: false,
    stock: "",
    minStock: "",
    keyWord: "",
    misspells: "",
    attributes: [],
    application: [],
    conversion: [],
    image: [],
  },
});

const state = {
  isLoading: false,
  isLoadingSearch: false,
  isSearching: false,
  product: initState().product,
  products: [],
  catalogs: [],
  groups: [],
  departments: [],
  errors: [],
  errorsProduct: {},
};

const mutations = {
  setLoading: (state, value) => (state.isLoading = value),
  setLoadingSearch: (state, value) => (state.isLoadingSearch = value),
  setSearching: (state, value) => (state.isSearching = value),
  setProducts(state, value) {
    state.products = value;
  },
  setOneProduct(state, value) {
    state.product = value;
  },
  setUpdatedProduct(state, value) {
    const index = state.products.findIndex((e) => e.id == value.id);
    state.products.splice(index, 1, value);
  },
  setMoreProducts(state, value) {
    state.products.push(...value);
  },
  setCatalogs(state, values) {
    state.catalogs = values;
  },
  setGroups(state, values) {
    state.groups = values;
  },
  setDepartment(state, values) {
    state.departments = values;
  },
  resetProductList(state) {
    state.products = [];
  },
  setErrorMessage(state, values) {
    state.errors = values;
  },
  setErrorsProduct(state, values) {
    state.errorsProduct = values;
  },
};

const actions = {
  async getAllProducts({ commit }) {
    commit("setSearching", false);
    const { data } = await http.get("product?page=1");
    commit("setProducts", data);
  },
  async getOneProduct({ commit }, id) {
    const { data } = await http.get(`product/details/${id}`);
    commit("setOneProduct", data);
  },
  async getMoreProducts({ commit }, page) {
    commit("setLoading", true);
    const { data } = await http.get(`product?page=${page}`);

    commit("setMoreProducts", data);
    commit("setLoading", false);
  },
  async searchProducts({ commit }, search) {
    commit("setSearching", true);
    commit("setLoadingSearch", true);

    const { data } = await http.get(`product/search?page=1&search=${search}`);
    commit("setProducts", data);
    commit("setLoadingSearch", false);
  },
  async getMoreSearch({ commit }, { page, search }) {
    commit("setSearching", true);
    commit("setLoading", true);

    const { data } = await http.get(
      `product/search?page=${page}&search=${search}`
    );

    commit("setMoreProducts", data);
    commit("setLoading", false);
  },
  async getProductCatalogs({ commit }) {
    const { data } = await http.get("catalog?noPagination=true");
    commit("setCatalogs", data);
  },
  async getProductGroup({ commit }) {
    const { data } = await http.get("catalog/indexes");
    commit("setGroups", data);
  },
  async getProductDepartment({ commit }) {
    const { data } = await http.get("department?noPagination=true");
    commit("setDepartment", data);
  },
  async updateProduct({ commit }, values) {
    commit("setErrorsProduct", {});
    try {
      const { width, height, length } = values;

      values.width = Number(width && String(width).replace(",", "."));
      values.height = Number(height && String(height).replace(",", "."));
      values.length = Number(length && String(length).replace(",", "."));

      const { data } = await http.put(`product/updateProduct/${values.id}`, {
        departmentId: values.departmentId,
        catalogId: values.catalogId,
        groupId: values.groupId,
        reference: values.reference || "",
        completeDescription: values.completeDescription,
        package: values.package || "",
        quantityPackage: values.quantityPackage || 0,
        unity: values.unity || "",
        tags: values.tags || "",
        showIpi: values.showIpi || false,
        news: values.news || false,
        height: values.height || 0,
        width: values.width || 0,
        length: values.length || 0,
        boxId: values.boxId,
        productId: values.productId,
      });

      commit("setUpdatedProduct", data);
      commit(
        "setToasted",
        {
          title: "Sucesso",
          message: "Produto atualizado com sucesso!",
          variant: "success",
          visible: true,
        },
        { root: true }
      );
    } catch (err) {
      if (err.response.status === 406) {
        commit("setErrorsProduct", err.response.data.error);
      } else {
        commit(
          "setToasted",
          {
            title: "ERRO",
            message: err.response.data.error,
            variant: "danger",
            visible: true,
          },
          { root: true }
        );
      }
    }
  },
  async hideProduct({ commit }, { id, values }) {
    try {
      commit("setErrorMessage", []);
      const { data } = await http.patch(`product/${id}/disable`, values);
      commit("setUpdatedProduct", data);
      commit(
        "setToasted",
        {
          title: "Sucesso",
          message: data.show
            ? "O Produto agora esta em exibição."
            : "O Produto não esta mais em exibição.",
          variant: "success",
          visible: true,
        },
        { root: true }
      );
    } catch (error) {
      commit("setErrorMessage", error.response.data.error.validate);
    }
  },
  async hideProductB2b({ commit }, { id, values }) {
    try {
      commit("setErrorMessage", []);
      const { data } = await http.patch(`product/${id}/b2b-disable`, values);
      commit("setUpdatedProduct", data);
      commit(
        "setToasted",
        {
          title: "Sucesso",
          message: data.showB2b
            ? "O Produto agora esta em exibição."
            : "O Produto não esta mais em exibição.",
          variant: "success",
          visible: true,
        },
        { root: true }
      );
    } catch (error) {
      commit("setErrorMessage", error.response.data.error.validate);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
