<template>
  <b-toast
    id="my-toast"
    :variant="variant"
    :visible="visible"
    solid
    @change="hideToasted"
  >
    <template #toast-title>
      <div class="d-flex flex-grow-1 align-items-baseline">
        <strong class="mr-auto">{{ title }}</strong>
      </div>
    </template>
    {{ message }}
  </b-toast>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      default: "success",
    },
    visible: {
      type: Boolean,
    },
  },
  name: "CustomToast",
  methods: {
    ...mapMutations(["hideToasted"]),
  },
};
</script>
