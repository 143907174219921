import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth";
import banner from "./banner";
import box from "./box";
import orders from "./orders";
import product from "./product";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    hideSideBar: true,
    showOverlay: false,
    toastedInfo: {
      title: "",
      message: "",
      variant: "",
      visible: false,
    },
  },
  mutations: {
    setSideBar(state) {
      state.hideSideBar = !state.hideSideBar;
    },
    setToasted(state, values) {
      state.toastedInfo.title = values.title;
      state.toastedInfo.message = values.message;
      state.toastedInfo.variant = values.variant;
      state.toastedInfo.visible = values.visible;
    },
    setToastedSuccess(state, values) {
      state.toastedInfo.title = values.title || "SUCESSO";
      state.toastedInfo.message = values.message;
      state.toastedInfo.variant = values.variant || "success";
      state.toastedInfo.visible = true;
    },
    setToastedError(state, values) {
      state.toastedInfo.title = values.title || "ERRO";
      state.toastedInfo.message = values.message;
      state.toastedInfo.variant = values.variant || "danger";
      state.toastedInfo.visible = true;
    },
    hideToasted(state) {
      state.toastedInfo.visible = !state.toastedInfo.visible;
    },
    setOverlay: (state, value) => (state.showOverlay = value),
  },
  actions: {},
  modules: {
    auth,
    box,
    product,
    orders,
    banner,
  },
});
