import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import { bannerRoutes } from "./banner.routes";
import { boxRoutes } from "./boxes.routes";
import { dashboardRoutes } from "./dashboard.routes";
import { operationRoutes } from "./operation.routes";
import { ordersRoutes } from "./orders.routes";
import { productRoutes } from "./products.routes";
import { userRoutes } from "./user.routes";
import { ordersB2bRoutes } from "./orderB2b.routes";
import { clientRoute } from "./clienteB2b.routes";
import { shippingCompanyRoutes } from "./shippingCompany.routes";
import { financialRoutes } from "./financial.routes";

const userPermission = store.getters["auth/userPermission"];

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "Home",
      component: () => import("../pages/index.vue"),
    },
    {
      path: "/troca-senha/:token",
      name: "ChangePassowrd",
      component: () => import("../pages/changePassword.vue"),
    },
    ...productRoutes,
    ...ordersRoutes,
    ...boxRoutes,
    ...bannerRoutes,
    ...userRoutes,
    ...operationRoutes,
    ...dashboardRoutes,
    ...ordersB2bRoutes,
    ...clientRoute,
    ...shippingCompanyRoutes,
    ...financialRoutes,
  ],
});

router.beforeEach(async (to, from, next) => {
  if (!store.getters["auth/isAuth"]) {
    await store.dispatch("auth/refresh");
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters["auth/isAuth"]) {
      next();
    } else {
      next("/");
    }
  } else {
    if (store.getters["auth/isAuth"]) {
      next("/dashboard");
    } else {
      next();
    }
  }

  if (to.matched.some((record) => record.meta.userPermission)) {
    const routeToGo = to.matched.find((route) => route.path == to.path);

    if (!userPermission(routeToGo.meta.userPermission)) {
      next();
    } else {
      next("/");
    }
  } else {
    next();
  }
});

export default router;
