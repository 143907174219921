import http from "../plugins/http";
const baseUrl = "/ecommerce/order";

const initState = () => ({
  order: {
    orderId: "",
    installments: "",
    amount: "",
    paymentMethod: "",
    zipcode: "",
    address: "",
    number: "",
    complement: "",
    references: "",
    district: "",
    city: "",
    state: "",
    statusId: "",
    total: "",
    mercadoPagoId: "",
    nfeId: "",
    client: {
      cpfCnpj: "",
    },
    statusClient: {},
    statusPanel: {},
    orderProducts: [],
    statusHistory: [],
    trackingInfo: {},
    createdAt: "",
    updatedAt: "",
  },
  query: {
    firstPeriod: "",
    finalPeriod: "",
    situation: "",
    paymentMethod: "",
  },
});

const state = {
  query: initState().query,
  order: initState().order,
  orders: [],
  canceled: [],
  loadMore: true,
  isSearching: false,
  isLoading: false,
  isLoadingSearch: false,
};

const mutations = {
  setIsLoading(state, value) {
    state.isLoading = value;
  },
  setIsLoadingSearch(state, value) {
    state.isLoadingSearch = value;
  },
  setIsSearching(state, value) {
    state.isSearching = value;
  },
  setAllOrders(state, values) {
    state.orders = values;
    state.loadMore = values.length === 20;
  },
  setAllCanceled(state, values) {
    state.canceled = values;
    state.loadMore = values.length === 20;
  },
  setMoreOrders(state, values) {
    state.loadMore = values.length === 20;
    state.orders = [...state.orders, ...values];
  },
  setOneOrder(state, value) {
    state.order = value;
  },
  changeQueryValue(state, { objectKey, value }) {
    state.query[objectKey] = value;
  },
  clearQueryValues(state) {
    state.query = initState().query;
  },
  clearOrderValues(state) {
    state.order = initState().order;
  },
  changeOrderState(state, { id, status }) {
    const order = state.orders.find((order) => order.orderId === id);
    order.statusPanel.description = status;
  },
};

const getters = {
  queryFilters(state) {
    const { situation, firstPeriod, finalPeriod, paymentMethod } = state.query;
    const query = new URLSearchParams();
    situation && query.append("situation", situation);
    paymentMethod && query.append("paymentMethod", paymentMethod);
    firstPeriod && query.append("firstPeriod", firstPeriod);
    finalPeriod && query.append("finalPeriod", finalPeriod);
    query.sort();
    return query.toString();
  },
};

const actions = {
  async getAllOrders({ commit, getters }) {
    commit("setIsLoading", true);
    commit("setIsSearching", false);
    const { queryFilters } = getters;
    try {
      const { data } = await http.get(`${baseUrl}?${queryFilters}`);
      commit("setAllOrders", data);
    } catch (err) {
      console.log(err);
    }
    commit("setIsLoading", false);
  },
  async getAllCanceled({ commit }) {
    commit("setIsLoading", true);
    commit("setIsSearching", false);

    try {
      const { data } = await http.get(`${baseUrl}?situation=Pedido Cancelado`);
      commit("setAllCanceled", data);
    } catch (err) {
      console.log(err);
    }
    commit("setIsLoading", false);
  },
  async getMoreAllOrders({ commit, getters }, page) {
    commit("setIsLoading", true);
    const { queryFilters } = getters;
    try {
      const url = `${baseUrl}?${queryFilters}&page=${page}`;
      const { data } = await http.get(url);
      commit("setMoreOrders", data);
    } catch (err) {
      console.log(err);
    }
    commit("setIsLoading", false);
  },
  async searchOrders({ commit, getters }, search) {
    commit("setIsSearching", true);
    commit("setIsLoadingSearch", true);
    const { queryFilters } = getters;
    try {
      const url = `${baseUrl}/search?${queryFilters}&search=${search}`;
      const { data } = await http.get(url);
      commit("setAllOrders", data);
    } catch (err) {
      console.log(err);
    }
    commit("setIsLoadingSearch", false);
  },
  async getMoreSearchOrders({ commit, getters }, { search, page }) {
    commit("setIsSearching", true);
    commit("setIsLoadingSearch", true);
    const { queryFilters } = getters;
    try {
      const url = `${baseUrl}/search?${queryFilters}&search=${search}&page=${page}`;
      const { data } = await http.get(url);
      commit("setMoreOrders", data);
    } catch (err) {
      console.log(err);
    }
    commit("setIsLoadingSearch", false);
  },
  async getOneOrder({ commit }, id) {
    try {
      const { data } = await http.get(`${baseUrl}/${id}`);
      commit("setOneOrder", data);
    } catch (err) {
      commit(
        "setToastedError",
        { message: err.response.data.error },
        { root: true }
      );
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
