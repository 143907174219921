const Dashboard = () => import("../layouts/dashboard.vue");
const ListShippingCompany = () =>
  import("../pages/shippingCompany/listShippingCompany.vue");
const DetailShippingCompany = () =>
  import("../pages/shippingCompany/detailShippingCompany.vue");
/**
 * @type {import('vue-router').RouteConfig[]}
 */
export const shippingCompanyRoutes = [
  {
    path: "/transportadoras",
    component: Dashboard,
    meta: { requiresAuth: true, userPermission: [2, 3] },
    children: [
      {
        path: "",
        component: ListShippingCompany,
      },
      {
        path: ":id/detalhes",
        component: DetailShippingCompany,
      },
    ],
  },
];
