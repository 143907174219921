const Dashboard = () => import("../layouts/dashboard.vue");
const listOrdersB2b = () => import("../pages/ordersB2b/listOrderB2b.vue");
const detailsOrderB2b = () => import("../pages/ordersB2b/detailsOrderB2b.vue");

/**
 * @type {import('vue-router').RouteConfig[]}
 */
export const ordersB2bRoutes = [
  {
    path: "/pedidosb2b",
    component: Dashboard,
    meta: { requiresAuth: true, userPermission: [2, 3] },
    children: [
      {
        path: "",
        component: listOrdersB2b,
      },
      {
        path: ":id/editar",
        component: detailsOrderB2b,
      },
    ],
  },
];
