import http from "../plugins/http";
const baseUrl = "/banner";

const initState = () => ({
  banner: {
    id: "",
    description: "",
    link: "",
    linkMobile: "",
    url: "",
    site: [],
    app: [],
    selected: "",
    active: false,
    linkPrefix: "",
  },
});

const state = {
  page: 2,
  banner: initState().banner,
  banners: [],
  loadMore: true,
  isSearching: false,
  isLoading: false,
  isLoadingSearch: false,
  errors: {},
};

const mutations = {
  setIsLoading(state, value) {
    state.isLoading = value;
  },
  setAllBanners(state, values) {
    state.banners = values;
    state.loadMore = values.length === 20;
  },
  setMoreBanners(state, values) {
    state.loadMore = values.length === 20;
    state.banners = [...state.banners, ...values];
  },
  setOneBanner(state, value) {
    let selected;
    if (value.secondary) {
      selected = { selected: "secondary" };
    } else if (value.inferior) {
      selected = { selected: "inferior" };
    } else {
      selected = { selected: "" };
    }

    state.banner = { ...value, ...selected };
  },
  setNewBanner(state, value) {
    state.banners = [...state.banners, value];
  },
  setUpdateBanner(state, value) {
    const index = state.banners.findIndex((banner) => banner.id === value.id);
    state.banners.splice(index, 1, value);
  },
  setRemoveBanner(state, id) {
    state.banners = state.banners.filter((banner) => banner.id !== id);
  },
  clearBannerValues(state) {
    state.banner = initState().banner;
  },
  incrementOrResetPage(state, reset) {
    !reset ? state.page++ : (state.page = 2);
  },
  setErrorsForm(state, value) {
    state.errors = value;
  },
};

const actions = {
  async getAllBanners({ commit }) {
    commit("setIsLoading", true);
    try {
      const { data } = await http.get(`${baseUrl}`);
      commit("setAllBanners", data);
    } catch (err) {
      console.log(err);
    }
    commit("setIsLoading", false);
    commit("incrementOrResetPage", true);
  },
  async getMoreAllBanners({ commit, state }) {
    commit("setIsLoading", true);
    try {
      const url = `${baseUrl}?page=${state.page}`;
      const { data } = await http.get(url);

      commit("setMoreBanners", data);
    } catch (err) {
      console.log(err);
    }
    commit("setIsLoading", false);
    commit("incrementOrResetPage", false);
  },
  async getOneBanner({ commit }, id) {
    try {
      const { data } = await http.get(`${baseUrl}/${id}`);
      commit("setOneBanner", data);
    } catch (err) {
      console.log(err);
    }
  },
  async createBanner({ commit }, values) {
    commit("setErrorsForm", {});
    try {
      values.inferior = values.selected === "inferior";
      values.secondary = values.selected === "secondary";
      const formToSend = new FormData();

      Object.entries(values).forEach(([key, value]) => {
        if (
          key !== "createdAt" &&
          key !== "updatedAt" &&
          key !== "selected" &&
          key !== "id" &&
          key !== "link" &&
          key !== "linkMobile"
        ) {
          formToSend.append(key, value);
        }
      });

      const { data } = await http.post(baseUrl, formToSend);

      commit("setOneBanner", data);
      commit(
        "setToastedSuccess",
        { message: "Banner criado com sucesso" },
        { root: true }
      );
    } catch (err) {
      if (err.response.status === 406) {
        commit("setErrorsForm", err.response.data.error);
      } else {
        commit(
          "setToastedError",
          { message: "Não foi póssivel criar Banner" },
          { root: true }
        );
      }
    }
  },
  async updateBanner({ commit }, { id, ...values }) {
    commit("setErrorsForm", {});
    try {
      const url = `${baseUrl}/${id}`;
      values.inferior = values.selected === "inferior";
      values.secondary = values.selected === "secondary";
      const formToSend = new FormData();

      Object.entries(values).forEach(([key, value]) => {
        if (key !== "createdAt" && key !== "updatedAt" && key !== "selected") {
          formToSend.append(key, value);
        }
      });

      const { data } = await http.put(url, formToSend);

      commit("setUpdateBanner", data);
      commit(
        "setToastedSuccess",
        { message: "Banner atualizado com sucesso" },
        { root: true }
      );
    } catch (err) {
      if (err.response.status === 406) {
        commit("setErrorsForm", err.response.data.error);
      } else {
        commit(
          "setToastedError",
          { message: "Não foi póssivel atualizar Banner" },
          { root: true }
        );
      }
    }
  },
  async removeBanner({ commit }, id) {
    try {
      const url = `${baseUrl}/${id}`;
      const { data } = await http.delete(url);
      commit("setRemoveBanner", data.id);
      commit(
        "setToastedSuccess",
        { message: "Banner removido com sucesso" },
        { root: true }
      );
    } catch (err) {
      commit(
        "setToastedError",
        { message: "Erro ao remover Banner" },
        { root: true }
      );
    }
  },
  async hideBanner({ commit }, { id, active }) {
    try {
      const url = `${baseUrl}/${id}`;
      const { data } = await http.patch(url, { active });
      commit("setUpdateBanner", data);
      commit(
        "setToastedSuccess",
        { message: "Banner desativado com sucesso" },
        { root: true }
      );
    } catch (err) {
      commit(
        "setToastedError",
        { message: "Erro ao desativar o Banner" },
        { root: true }
      );
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
