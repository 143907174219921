const Dashboard = () => import("../layouts/dashboard.vue");
const detailsOrder = () => import("../pages/orders/detailsOrder.vue");
const listOrders = () => import("../pages/orders/listOrder.vue");
const cancellationAndReturn = () =>
  import("../pages/orders/cancellationAndReturn.vue");

/**
 * @type {import('vue-router').RouteConfig[]}
 */
export const ordersRoutes = [
  {
    path: "/pedidos",
    component: Dashboard,
    meta: { requiresAuth: true, userPermission: [2, 3] },
    children: [
      {
        path: "",
        component: listOrders,
      },
      {
        path: ":id/editar",
        component: detailsOrder,
      },
      {
        path: "cancelamento",
        component: cancellationAndReturn,
      },
    ],
  },
];
